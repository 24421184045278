<div class="withdrawal-container">
  <div class="withdrawal-container__header">
    <p *ngIf="!viewState.paymentSuccessful" class="heading2--bold content-main-color">
      {{ 'WALLET.WITHDRAWALS.WITHDRAW_PROFIT' | translate }}
    </p>
    <button
      class="withdrawal-container__close-btn"
      (click)="presenter.emitViewEvent({
      type: 'ClickCloseDialog',
    })"
    >
      <img src="assets/img/wallet-icons/close-circle.svg" />
    </button>
  </div>
  <div *ngIf="viewState.shouldShowOTP && !viewState.paymentSuccessful" class="new-header">
    <ng-container *ngTemplateOutlet="section_header; context: { sectionNumber: 1 }"></ng-container>
    <p class="body2--bold content-main-color">
      {{ 'WALLET.WITHDRAWALS.WITHDRAWAL_METHODS_TITLE' | translate }}
    </p>
  </div>
  <form *ngIf="!viewState.paymentSuccessful" [formGroup]="withdrawalRequestForm">
    <label for="withdrawalAmountField">{{
      'WALLET.WITHDRAWALS.WITHDRAWAL_AMOUNT' | translate
    }}</label>
    <mat-form-field
      appearance="outline"
      id="withdrawalAmountField"
      [ngClass]="{
        'form-field-error': withdrawalRequestForm.controls.withdrawalAmount.errors?.min
      }"
    >
      <input
        matInput
        required
        type="number"
        id="withdrawalAmount"
        formControlName="withdrawalAmount"
        (input)="
          this.presenter.emitViewEvent({
            type: 'WithdrawalAmountChanged',
            amount: $event.target.value
          })
        "
        [min]="viewState.minWithdrawalAmount"
      />
      <span matSuffix>{{ viewState.currency }}</span>
      <mat-error *ngIf="withdrawalRequestForm.controls.withdrawalAmount.invalid"
        >{{
          getAmountError(withdrawalRequestForm.controls.withdrawalAmount.errors)
            | translate : { amount: viewState.minWithdrawalAmount }
        }}
      </mat-error>
    </mat-form-field>

    <div class="withdrawal-container__label-warning d-flex align-items-center">
      <label for="withdrawalMethod">{{ 'WALLET.WITHDRAWALS.WITHDRAWAL_METHOD' | translate }}</label>
      <p *ngIf="viewState.shouldDisplayDisclaimer" class="withdrawal-container__warning-text">
        <i class="icon icon-info"></i>
        {{ 'WALLET.WITHDRAWAL_DIALOG.DISCLAIMER' | translate }}
      </p>
    </div>

    <mat-form-field appearance="outline" id="withdrawalMethod">
      <mat-select
        name="withdrawalMethod"
        formControlName="withdrawalMethod"
        (valueChange)="
          presenter.emitViewEvent({ type: 'WithdrawalMethodChanged', paymentMethod: $event })
        "
      >
        <ng-container *ngFor="let withdrawalMethod of viewState.withdrawalMethods">
          <mat-option [value]="withdrawalMethod">
            {{ mapWithdrawalMethods(withdrawalMethod) | translate }}
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="withdrawalRequestForm.controls.withdrawalMethod.invalid"
        >{{ 'WALLET.WITHDRAWALS.WITHDRAWAL_METHOD_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>

    <label
      for="withdrawalPhoneNumber"
      *ngIf="viewState.selectedPaymentType === viewState.paymentTypes.MOBILE_WALLET"
      >{{ 'WALLET.WITHDRAWALS.MOBILE_NUMBER' | translate
      }}<span>({{ 'WALLET.WITHDRAWALS.ENGLISH_DIGITS_REQUIRED' | translate }})</span></label
    >
    <mat-form-field
      appearance="outline"
      id="withdrawalPhoneNumber"
      *ngIf="viewState.selectedPaymentType === viewState.paymentTypes.MOBILE_WALLET"
    >
      <input matInput required id="withdrawalPhoneNumber" formControlName="withdrawalPhoneNumber" />
      <mat-error *ngIf="withdrawalRequestForm.controls.withdrawalPhoneNumber.invalid"
        >{{ getPhoneNumberError(withdrawalRequestForm.controls.withdrawalPhoneNumber.errors) }}
      </mat-error>
    </mat-form-field>

    <ng-container
      *ngIf="
        viewState.selectedPaymentType === viewState.paymentTypes.BANK_TRANSFER &&
        data.country.isoCode3 !== 'EGY'
      "
    >
      <label for="withdrawalAccountNumber"
        >{{ 'WALLET.WITHDRAWALS.ACCOUNT_NUMBER' | translate
        }}<span
          >({{ 'WALLET.WITHDRAWALS.ENGLISH_DIGITS_AND_LETTERS_REQUIRED' | translate }})</span
        ></label
      >
      <mat-form-field
        appearance="outline"
        id="withdrawalAccountNumber"
        *ngIf="viewState.selectedPaymentType === viewState.paymentTypes.BANK_TRANSFER"
      >
        <input
          matInput
          required
          id="withdrawalAccountNumber"
          formControlName="withdrawalAccountNumber"
        />
        <mat-error *ngIf="withdrawalRequestForm.controls.withdrawalAccountNumber.invalid"
          >{{ 'WALLET.WITHDRAWALS.ACCOUNT_NUMBER_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <div
      class="field-wrapper w-full"
      *ngIf="viewState.selectedPaymentType === viewState.paymentTypes.DIGITAL_PAYMENT"
    >
      <label for="withdrawalAccountNumber">{{ 'WALLET.WITHDRAWALS.EMAIL' | translate }}</label>
      <mat-form-field appearance="outline" id="beneficiaryEmail">
        <input
          matInput
          required
          type="email"
          id="beneficiaryEmail"
          formControlName="beneficiaryEmail"
        />
        <mat-error *ngIf="withdrawalRequestForm.controls.beneficiaryEmail.invalid"
          >{{ 'WALLET.WITHDRAWALS.EMAIL_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- START International Bank Transfer Conditional Fields -->
    <!-- Update: Now, Internation Bank Transfer and Bank Transfer (only for Egypt) should have the same fields -->
    <div
      class="intl-bank-fields-wrapper"
      *ngIf="viewState.selectedPaymentType === viewState.paymentTypes.BANK_TRANSFER"
    >
      <div class="field-wrapper">
        <label for="beneficiaryBankName">{{ 'WALLET.WITHDRAWALS.BANK_NAME' | translate }}</label>
        <mat-form-field appearance="outline" id="beneficiaryBankName">
          <input matInput required id="beneficiaryBankName" formControlName="beneficiaryBankName" />
          <mat-error *ngIf="withdrawalRequestForm.controls.beneficiaryBankName.invalid"
            >{{ 'WALLET.WITHDRAWALS.BANK_NAME_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field-wrapper">
        <label for="beneficiaryBankAddress">{{
          'WALLET.WITHDRAWALS.BANK_ADDRESS' | translate
        }}</label>
        <mat-form-field appearance="outline" id="beneficiaryBankAddress">
          <input
            matInput
            required
            id="beneficiaryBankAddress"
            formControlName="beneficiaryBankAddress"
          />
          <mat-error *ngIf="withdrawalRequestForm.controls.beneficiaryBankAddress.invalid"
            >{{ 'WALLET.WITHDRAWALS.BANK_ADDRESS_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field-wrapper">
        <label for="beneficiaryBankSwiftNumber">{{
          'WALLET.WITHDRAWALS.SWIFT_NUMBER' | translate
        }}</label>
        <mat-form-field appearance="outline" id="beneficiaryBankSwiftNumber">
          <input
            matInput
            required
            id="beneficiaryBankSwiftNumber"
            formControlName="beneficiaryBankSwiftNumber"
          />
          <mat-error *ngIf="withdrawalRequestForm.controls.beneficiaryBankSwiftNumber.invalid"
            >{{ 'WALLET.WITHDRAWALS.SWIFT_NUMBER_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field-wrapper">
        <label for="beneficiaryName">{{ 'WALLET.WITHDRAWALS.BENEFICIARY_NAME' | translate }}</label>
        <mat-form-field appearance="outline" id="beneficiaryName">
          <input matInput required id="beneficiaryName" formControlName="beneficiaryName" />
          <mat-error *ngIf="withdrawalRequestForm.controls.beneficiaryName.invalid"
            >{{ 'WALLET.WITHDRAWALS.BENEFICIARY_NAME_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field-wrapper">
        <label for="beneficiaryAddress">{{
          'WALLET.WITHDRAWALS.BENEFICIARY_ADDRESS' | translate
        }}</label>
        <mat-form-field appearance="outline" id="beneficiaryAddress">
          <input matInput required id="beneficiaryAddress" formControlName="beneficiaryAddress" />
          <mat-error *ngIf="withdrawalRequestForm.controls.beneficiaryAddress.invalid"
            >{{ 'WALLET.WITHDRAWALS.BENEFICIARY_ADDRESS_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field-wrapper">
        <label for="beneficiaryContactNumber">{{
          'WALLET.WITHDRAWALS.BENEFICIARY_CONTACT_NUMBER' | translate
        }}</label>
        <mat-form-field appearance="outline" id="beneficiaryContactNumber">
          <input
            matInput
            required
            id="beneficiaryContactNumber"
            formControlName="beneficiaryContactNumber"
          />
          <mat-error *ngIf="withdrawalRequestForm.controls.beneficiaryContactNumber.invalid"
            >{{ 'WALLET.WITHDRAWALS.BENEFICIARY_CONTACT_NUMBER_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field-wrapper w-full padded">
        <label for="beneficiaryAccountIban">{{ 'WALLET.WITHDRAWALS.IBAN' | translate }}</label>
        <mat-form-field appearance="outline" id="beneficiaryAccountIban">
          <input
            matInput
            required
            id="beneficiaryAccountIban"
            formControlName="beneficiaryAccountIban"
          />
          <mat-error *ngIf="withdrawalRequestForm.controls.beneficiaryAccountIban.invalid"
            >{{ 'WALLET.WITHDRAWALS.IBAN_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="viewState.shouldShowOTP" class="new-header">
      <ng-container *ngTemplateOutlet="section_header; context: { sectionNumber: 2 }">
      </ng-container>

      <p class="body2--bold content-main-color">
        {{ 'WALLET.WITHDRAWALS.SECURITY_STEPS' | translate }}
      </p>
      <p class="caption2--regular content-main-color">
        {{ 'WALLET.WITHDRAWALS.CONFIRM' | translate }}
      </p>
      <div class="withdrawal-options-container" *ngIf="viewState.isEmailOTPEnabled">
        <div
          class="withdraw-option"
          *ngFor="let otpOption of viewState.withdrawOTPOptions"
          [ngClass]="{ 'active-option': otpOption.isActive }"
          (click)="
            presenter.emitViewEvent({
              type: 'UpdateOTPOption',
              value: otpOption.value
            })
          "
        >
          <div class="label-input-btn">
            <input
              class="check-input"
              type="radio"
              [name]="otpOption.value"
              [id]="otpOption.value"
              [checked]="otpOption.isActive"
            />
            <label class="form-check-label">
              {{ otpOption.name }}
            </label>
            <img class="check-img" [src]="'assets/img/' + otpOption.icon" />
          </div>
        </div>
      </div>
      <div class="withdrawal-input-container" *ngIf="viewState.selectedOTPOption === 'sms'">
        <p class="body2--regular content-main-color">
          {{ 'WALLET.WITHDRAWALS.PHONE_NUMBER' | translate }}
        </p>
        <input
          class="withdrawal-input disabled-input body1--regular"
          value="{{ viewState.verifiedPhoneNumber }}"
          disabled
        />
      </div>
      <div class="withdrawal-input-container" *ngIf="viewState.selectedOTPOption === 'email'">
        <p class="body2--regular content-main-color">
          {{ 'WALLET.WITHDRAWALS.EMAIL' | translate }}
        </p>
        <input
          class="withdrawal-input disabled-input body1--regular"
          value="{{ viewState.verifiedEmail }}"
          disabled
        />
      </div>
      <div class="withdrawal-input-container">
        <p class="body2--regular content-main-color">
          {{ 'WALLET.WITHDRAWALS.OTP_CODE' | translate }}
        </p>
        <div class="withdrawal-input-container__otp">
          <input
            class="withdrawal-otp-input body1--regular"
            placeholder="{{ 'WALLET.WITHDRAWALS.OTP_CODE' | translate }}"
            type="number"
            formControlName="otpPassCode"
          />
          <button
            *ngIf="viewState.remainingTimeUntilNextOTP === null"
            class="withdrawal-input-container__otp__button body1--medium"
            (click)="
              presenter.emitViewEvent(
                viewState.selectedOTPOption === 'sms'
                  ? {
                      type: 'RequestOTP'
                    }
                  : {
                      type: 'RequestEmailOTP'
                    }
              )
            "
          >
            {{ 'WALLET.WITHDRAWALS.SEND_OTP' | translate }}
          </button>
          <button
            *ngIf="viewState.remainingTimeUntilNextOTP !== null"
            class="withdrawal-button withdrawal-button__re-send body1--medium"
          >
            {{ 'WALLET.WITHDRAWALS.OTP_RESEND' | translate }}
            {{ viewState.remainingTimeUntilNextOTP }}
          </button>
        </div>
        <p
          class="caption2--regular content-medium-color"
          *ngIf="viewState.remainingTimeUntilNextOTP !== null"
        >
          {{ 'WALLET.WITHDRAWALS.CHECK_MESSAGE' | translate }}
        </p>
      </div>
    </div>
    <!-- END International Bank Transfer Conditional Fields -->
    <div class="buttons-container">
      <div class="buttons-container__right-part">
        <button
          type="submit"
          (click)="onSubmit()"
          class="withdrawal-button withdrawal-button__submit body1--bold"
          [disabled]="viewState.isSubmittingRequest"
        >
          {{ 'WALLET.WITHDRAWALS.WITHDRAWAL_SUBMIT' | translate }}
        </button>
        <button
          *ngIf="viewState.shouldShowOTP"
          class="withdrawal-button withdrawal-button__no-access body1--bold"
          (click)="
            presenter.emitViewEvent(
              viewState.selectedOTPOption === 'sms'
                ? {
                    type: 'RequestPhoneNumberChange'
                  }
                : {
                    type: 'RequestEmailChange'
                  }
            )
          "
        >
          {{
            viewState.selectedOTPOption === 'sms'
              ? trans('WALLET.WITHDRAWALS.CANNOT_ACCESS')
              : trans('WALLET.WITHDRAWALS.CANNOT_ACCESS_EMAIL')
          }}
        </button>
      </div>
      <button
        class="withdrawal-button withdrawal-button__cancel body1--bold"
        (click)="presenter.emitViewEvent({
        type: 'ClickCloseDialog',
      })"
      >
        {{ 'WALLET.WITHDRAWALS.CANCEL' | translate }}
      </button>
    </div>
  </form>

  <app-success-message
    *ngIf="viewState.paymentSuccessful"
    message="{{ 'WALLET.WITHDRAWALS.WITHDRAWAL_SUCCESSFUL' | translate }}"
    (closeEvent)="presenter.emitViewEvent({
      type: 'ClickCloseDialog',
    })"
  ></app-success-message>
</div>

<ng-template #section_header let-sectionNumber="sectionNumber">
  <div class="section-title">
    <div class="line"></div>
    <p class="text caption2--medium">
      {{ 'WALLET.WITHDRAWALS.STEP' | translate }} {{ sectionNumber }}
    </p>
    <div class="line"></div>
  </div>
</ng-template>
