import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { WithdrawalMethods } from 'src/app/core/domain/payment/payment-methods.model';
import { BaseComponent } from '../../base/base.component';
import { SuccessMessageComponent } from '../../shared/components/success-message/success-message.component';
import { CURRENCIES } from '../../shared/constants';
import { Country } from '../../shared/interfaces/countries';
import { WithdrawalPresenter } from './presentation/withdrawal.presenter';
import { WithdrawalSideEffect } from './presentation/withdrawal.side-effects';
import { WithdrawalViewEvent } from './presentation/withdrawal.view-events';
import { WithdrawalViewState } from './presentation/withdrawal.view-state';
import { WithdrawalChangeNumberComponent } from './withdrawal-change-number/withdrawal-change-number.component';

@Component({
  selector: 'app-withdrawal-dialog',
  templateUrl: './withdrawal-dialog.component.html',
  styleUrls: ['./withdrawal-dialog.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    SuccessMessageComponent,
    TranslateModule,
    NgTemplateOutlet,
    NgClass,
    MatRadioModule,
  ],
  providers: [WithdrawalPresenter],
})
export class WithdrawalDialogComponent extends BaseComponent<
  WithdrawalPresenter,
  WithdrawalViewState,
  WithdrawalViewEvent,
  WithdrawalSideEffect
> {
  public withdrawalRequestForm: UntypedFormGroup;

  public presenter: WithdrawalPresenter = inject(WithdrawalPresenter);

  private dialogRef: MatDialog = inject(MatDialog);

  private toaster: ToastrService = inject(ToastrService);

  public data: { country: Country } = inject(MAT_DIALOG_DATA);

  private _translateService: TranslateService = inject(TranslateService);

  public onInit(): void {
    this.presenter.setFieldsValidityChecker(() => this.checkFormValidty());

    this.presenter.emitViewEvent({
      type: 'Init',
      currency: this.data.country.currency.iso4217Code,
    });

    this.createForm();
  }

  onSideEffect(sideEffect: WithdrawalSideEffect): void {
    switch (sideEffect.type) {
      case 'ShowError': {
        this.toaster.error(this._translateService.instant(sideEffect.message));
        break;
      }
      case 'ShowChangeNumber':
      case 'ShowChangeEmail': {
        this.dialogRef.closeAll();
        const dialog = this.dialogRef.open(WithdrawalChangeNumberComponent, {
          width: '950px',
        });
        dialog.componentInstance.url = sideEffect.url;
        break;
      }
      case 'CloseDialog': {
        this.dialogRef.closeAll();
      }
    }
  }

  createForm(): void {
    this.withdrawalRequestForm = new UntypedFormGroup({
      withdrawalAmount: new UntypedFormControl('', [Validators.required]),
      withdrawalMethod: new UntypedFormControl('', [Validators.required]),
      withdrawalPhoneNumber: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^01[0125][0-9]{8}$'),
      ]),
      // international bank transfer fields
      withdrawalAccountNumber: new UntypedFormControl('', [Validators.required]),
      beneficiaryBankName: new UntypedFormControl('', [Validators.required]),
      beneficiaryBankAddress: new UntypedFormControl('', [Validators.required]),
      beneficiaryBankSwiftNumber: new UntypedFormControl('', [Validators.required]),
      beneficiaryName: new UntypedFormControl('', [Validators.required]),
      beneficiaryAddress: new UntypedFormControl('', [Validators.required]),
      beneficiaryContactNumber: new UntypedFormControl('', [Validators.required]),
      beneficiaryAccountIban: new UntypedFormControl('', [Validators.required]),
      // payoneer field
      beneficiaryEmail: new UntypedFormControl('', [Validators.required]),
      otpPassCode: new UntypedFormControl('', [Validators.required]),
    });
  }

  getAmountError(errors: ValidationErrors | null): string {
    if (errors?.required) {
      return `WALLET.WITHDRAWAL_DIALOG.ERRORS.REQUIRED_FIELD_ERROR`;
    }
    if (errors?.min) {
      switch (this.viewState.selectedWithdrawalMethod) {
        case WithdrawalMethods.INTL_BANK_TRANSFER_METHOD:
          return `WALLET.WITHDRAWAL_DIALOG.ERRORS.WITHDRAWAL_AMOUNT_ERROR.INTERNATIONAL_BANK_TRANSFER.${this.viewState.currency}.MIN`;
        case WithdrawalMethods.EGP_BANK_TRANSFER_METHOD:
          return `WALLET.WITHDRAWAL_DIALOG.ERRORS.WITHDRAWAL_AMOUNT_ERROR.BANK_TRANSFER.MIN`;
        default:
          return `WALLET.WITHDRAWAL_DIALOG.ERRORS.WITHDRAWAL_AMOUNT_ERROR.REQUIRED_FIELD_ERROR`;
      }
    } else {
      return `WALLET.WITHDRAWAL_DIALOG.ERRORS.GENERAL_ERROR`;
    }
  }

  getPhoneNumberError(errors: ValidationErrors | null): string {
    if (errors?.required) {
      return 'يجب ملء خانة رقم الموبايل';
    }
    if (errors?.pattern) {
      // eslint-disable-next-line id-blacklist
      const number = errors?.pattern.actualValue;
      // eslint-disable-next-line id-blacklist
      if (number.length < 11) {
        return 'رقم الموبايل اقل من 11 رقم - يجب ان يحتوي على 11 رقم';
      }
      // eslint-disable-next-line id-blacklist
      if (number.length > 11) {
        return 'رقم الموبايل اكبر من 11 رقم - يجب ان يحتوي على 11 رقم';
      }
      if (!/^(01)/.test(number)) {
        return 'يجب ان يبدأ رقم الموبايل ب 01';
      }
      if (/\s/.test(number)) {
        return 'يجب الا يحتوى رقم الموبايل على اي مسافات';
      }
      return 'رقم الموبايل غير صحيح ويجب ان لا يكون باللغة العربية';
    }
    return 'رقم الموبايل غير صحيح ويجب ان لا يكون باللغة العربية';
  }

  mapWithdrawalMethods(withdrawal: WithdrawalMethods): string {
    const prefix = 'WALLET.WITHDRAWALS.METHODS.';
    switch (withdrawal) {
      case WithdrawalMethods.VODAFONE_CASH_METHOD:
        return `${prefix}VODAFONE_CASH_METHOD`;
      case WithdrawalMethods.ORANGE_CASH_METHOD:
        return `${prefix}ORANGE_CASH_METHOD`;
      case WithdrawalMethods.ETISALAT_CASH_METHOD:
        return `${prefix}ETISALAT_CASH_METHOD`;
      case WithdrawalMethods.WE_PAY_METHOD:
        return `${prefix}WE_PAY_METHOD`;
      case WithdrawalMethods.EGP_BANK_TRANSFER_METHOD:
        return `${prefix}BANK_TRANSFER_METHOD`;
      case WithdrawalMethods.INTL_BANK_TRANSFER_METHOD:
        return `${prefix}INTL_BANK_TRANSFER_METHOD`;
      case WithdrawalMethods.PAYONEER_TRANSFER_METHOD:
        return `${prefix}PAYONEER_TRANSFER_METHOD`;
      default:
        return '';
    }
  }

  checkFormValidty(): boolean {
    if (this.viewState.selectedWithdrawalMethod === WithdrawalMethods.INTL_BANK_TRANSFER_METHOD) {
      if (
        !this.withdrawalRequestForm.controls.beneficiaryBankName.errors &&
        !this.withdrawalRequestForm.controls.beneficiaryBankAddress.errors &&
        !this.withdrawalRequestForm.controls.beneficiaryBankSwiftNumber.errors &&
        !this.withdrawalRequestForm.controls.beneficiaryName.errors &&
        !this.withdrawalRequestForm.controls.beneficiaryAddress.errors &&
        !this.withdrawalRequestForm.controls.beneficiaryContactNumber.errors &&
        !this.withdrawalRequestForm.controls.beneficiaryAccountIban.errors &&
        !this.withdrawalRequestForm.controls.withdrawalAmount.errors
      ) {
        return true;
      }
    } else if (
      this.viewState.selectedWithdrawalMethod === WithdrawalMethods.PAYONEER_TRANSFER_METHOD
    ) {
      if (!this.withdrawalRequestForm.controls.beneficiaryEmail.errors) {
        return true;
      }
    } else if (
      this.viewState.selectedWithdrawalMethod !== WithdrawalMethods.EGP_BANK_TRANSFER_METHOD
    ) {
      if (
        !this.withdrawalRequestForm.controls.withdrawalAmount.errors &&
        !this.withdrawalRequestForm.controls.withdrawalMethod.errors &&
        !this.withdrawalRequestForm.controls.withdrawalPhoneNumber.errors
      ) {
        return true;
      }
    } else if (
      this.viewState.selectedWithdrawalMethod === WithdrawalMethods.EGP_BANK_TRANSFER_METHOD &&
      this.data.country.currency.englishName === CURRENCIES.EGP
    ) {
      if (
        !this.withdrawalRequestForm.controls.beneficiaryBankName.errors &&
        !this.withdrawalRequestForm.controls.beneficiaryBankAddress.errors &&
        !this.withdrawalRequestForm.controls.beneficiaryBankSwiftNumber.errors &&
        !this.withdrawalRequestForm.controls.beneficiaryName.errors &&
        !this.withdrawalRequestForm.controls.beneficiaryAddress.errors &&
        !this.withdrawalRequestForm.controls.beneficiaryContactNumber.errors &&
        !this.withdrawalRequestForm.controls.beneficiaryAccountIban.errors &&
        !this.withdrawalRequestForm.controls.withdrawalAmount.errors
      ) {
        return true;
      }
    } else if (
      !this.withdrawalRequestForm.controls.withdrawalAmount.errors &&
      !this.withdrawalRequestForm.controls.withdrawalMethod.errors &&
      !this.withdrawalRequestForm.controls.withdrawalAccountNumber.errors
    ) {
      return true;
    }
    return false;
  }

  onSubmit(): void {
    if (this.checkFormValidty()) {
      if (
        this.viewState.selectedWithdrawalMethod === WithdrawalMethods.INTL_BANK_TRANSFER_METHOD ||
        this.viewState.selectedWithdrawalMethod === WithdrawalMethods.EGP_BANK_TRANSFER_METHOD
      ) {
        const request = {
          amount: this.withdrawalRequestForm.value.withdrawalAmount,
          currency: this.data.country.currency,
          beneficiaryBank: {
            name: this.withdrawalRequestForm.value.beneficiaryBankName,
            address: this.withdrawalRequestForm.value.beneficiaryBankAddress,
            swiftNumber: this.withdrawalRequestForm.value.beneficiaryBankSwiftNumber,
          },
          beneficiary: {
            name: this.withdrawalRequestForm.value.beneficiaryName,
            address: this.withdrawalRequestForm.value.beneficiaryAddress,
            contactNumber: this.withdrawalRequestForm.value.beneficiaryContactNumber,
          },
          beneficiaryAccount: {
            iban: this.withdrawalRequestForm.value.beneficiaryAccountIban,
          },
          otpVerificationMode: this.viewState.selectedOTPOption,
        };
        this.presenter.createIntlBankTransferWithdrawalRequest(
          request,
          this.withdrawalRequestForm.value.otpPassCode,
        );
      } else if (
        this.viewState.selectedWithdrawalMethod === WithdrawalMethods.PAYONEER_TRANSFER_METHOD
      ) {
        const request = {
          amount: this.withdrawalRequestForm.value.withdrawalAmount,
          currency: this.data.country.currency,
          beneficiary: {
            email: this.withdrawalRequestForm.value.beneficiaryEmail,
          },
          otpVerificationMode: this.viewState.selectedOTPOption,
        };
        this.presenter.createPayoneerWithdrawalRequest(
          request,
          this.withdrawalRequestForm.value.otpPassCode,
        );
      } else {
        const request = {
          amount: this.withdrawalRequestForm.value.withdrawalAmount,
          currency: this.data.country.currency,
          paymentMethod: this.withdrawalRequestForm.value.withdrawalMethod,
          phoneNum: this.withdrawalRequestForm.value.withdrawalPhoneNumber,
          otpVerificationMode: this.viewState.selectedOTPOption,
        };
        this.presenter.doMakeDefaultWithdrawalRequest(
          request,
          this.withdrawalRequestForm.value.otpPassCode,
        );
      }
    }
  }
}
