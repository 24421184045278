<div class="tally-form">
  <button class="close-btn" (click)="onClose()">
    <i class="icon icon-header-icons-close close-btn-icon"></i>
    <p class="close-btn-text body2--medium">إغلاق</p>
  </button>
  <iframe
    [attr.data-tally-src]="url"
    loading="lazy"
    width="100%%"
    height="750"
    frameborder="0"
    marginheight="0"
    marginwidth="0"
    title="Change phone number"
  ></iframe>
</div>
