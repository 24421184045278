import { Injectable } from '@angular/core';
import { user } from '@features/user/data';
import { trans } from '@mongez/localization';
import { parseError } from '@presentation/shared/error';
import { RequestEmailOTPUseCase } from 'app/core/usecases/auth/request-email-otp.usecase';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import { interval, last, Observable, Subscription, take } from 'rxjs';
import {
  PaymentTypes,
  WithdrawalMethods,
  WithdrawalsPaymentTypes,
} from 'src/app/core/domain/payment/payment-methods.model';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { CURRENCIES } from 'src/app/presentation/shared/constants';
import {
  WithdrawalError,
  WithdrawalOTPError,
} from '../../../../core/domain/payment/withdrawal-errors';
import { LogGTagEventUseCase } from '../../../../core/usecases/analytics/log-gtag-event.usecase';
import { LogMixpanelEventUseCase } from '../../../../core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from '../../../../core/usecases/get-feature-flag.usecase';
import { GetUserCountryUseCase } from '../../../../core/usecases/user-location/get-user-country.usecase';
import { CheckUserFeatureExistsUseCase } from '../../../../core/usecases/user/check-user-feature-exists.usecase';
import { CreateSecureIntlBankTransferUseCase } from '../../../../core/usecases/wallet/create-secure-intl-bank-transfer.usecase';
import { CreateSecurePayoneerUseCase } from '../../../../core/usecases/wallet/create-secure-payoneer.usecase';
import { CreateSecureWithdrawalRequestUseCase } from '../../../../core/usecases/wallet/create-secure-withdrawal-request-use.case';
import { CreateWithdrawalRequestUseCase } from '../../../../core/usecases/wallet/create-withdrawal-request.usecase';
import { RequestWithdrawalOtpUseCase } from '../../../../core/usecases/wallet/request-withdrawal-otp.usecase';
import { WithdrawIntlBankTransferUseCase } from '../../../../core/usecases/wallet/withdraw-intl-bank-transfer.usecase';
import { WithdrawPayoneerUseCase } from '../../../../core/usecases/wallet/withdraw-payoneer.usecase';
import {
  EMAIL_OTP_WITHDRAW,
  ENHANCED_WALLET_SECURITY,
} from '../../../shared/constants/feature-flags';
import { WithdrawalSideEffect } from './withdrawal.side-effects';
import { WithdrawalViewEvent } from './withdrawal.view-events';
import { WithdrawalViewState } from './withdrawal.view-state';

const OTP_SECURED_WITHDRAWAL_USER_FEATURE = 'withdrawal_otp';
const PHONE_NUMBER_CHANGE_FORM_URL = 'https://tally.so/r/m62Ao5';
const EMAIL_CHANGE_FORM_URL = 'https://tally.so/r/nPA2pd';

@Injectable()
export class WithdrawalPresenter extends BasePresenter<
  WithdrawalViewState,
  WithdrawalViewEvent,
  WithdrawalSideEffect
> {
  private readonly _isOTPEnforced: boolean;

  private _checkCode?: string;

  private otpTimerSubscription: Subscription | null = null;

  fieldsValidityChecker: () => boolean;

  private _withdrawalMethodsList = Object.values(WithdrawalMethods).filter(
    (i) => !isNaN(Number(i)),
  ) as WithdrawalMethods[];

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    private _createSecureIntlBankTransferUseCase: CreateSecureIntlBankTransferUseCase,
    private _createSecurePayoneerUseCase: CreateSecurePayoneerUseCase,
    private _createSecureWithdrawalRequestUseCase: CreateSecureWithdrawalRequestUseCase,
    private _requestWithdrawalOtpUseCase: RequestWithdrawalOtpUseCase,
    private _createWithdrawalRequestUseCase: CreateWithdrawalRequestUseCase,
    private _withdrawIntlBankTransferUseCase: WithdrawIntlBankTransferUseCase,
    private _withdrawPayoneerUseCase: WithdrawPayoneerUseCase,
    private _getUserCountryUseCase: GetUserCountryUseCase,
    private _logGTagEventUseCase: LogGTagEventUseCase,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    private _requestEmailOTPUseCase: RequestEmailOTPUseCase,
  ) {
    super();
    this._isOTPEnforced = this._checkUserFeatureExistsUseCase.execute(
      OTP_SECURED_WITHDRAWAL_USER_FEATURE,
    );
  }

  protected defaultViewState(): WithdrawalViewState {
    return {
      paymentSuccessful: false,
      isSubmittingRequest: false,
      shouldShowOTP: false,
      verifiedPhoneNumber: '',
      remainingTimeUntilNextOTP: null,
      minWithdrawalAmount: 1,
      withdrawalMethods: this._withdrawalMethodsList,
      paymentTypes: PaymentTypes,
      selectedWithdrawalMethod: null,
      selectedPaymentType: null,
      currency: null,
      shouldDisplayDisclaimer: false,
      withdrawOTPOptions: null,
      selectedOTPOption: 'sms',
      verifiedEmail: '',
      isEmailOTPEnabled: false,
    };
  }

  protected onViewEvent(event: WithdrawalViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this.viewState.withdrawOTPOptions = [
          {
            name: trans('WALLET.WITHDRAWALS.SEND_CODE_PHONE'),
            value: 'sms',
            isActive: true,
            icon: 'sms-otp-icon.svg',
          },
          {
            name: trans('WALLET.WITHDRAWALS.SEND_CODE_EMAIL'),
            value: 'email',
            isActive: false,
            icon: 'email-otp-icon.svg',
          },
        ];
        this._getFeatureFlagUseCase.execute(ENHANCED_WALLET_SECURITY).subscribe({
          next: (isFirebaseFlagEnabled) => {
            if (this._isOTPEnforced && isFirebaseFlagEnabled) {
              this._shouldShowEmailOTP();
            }
            this.updateViewState({
              ...this.viewState,
              shouldShowOTP: this._isOTPEnforced && isFirebaseFlagEnabled,
              verifiedPhoneNumber: user.phoneNumber,
              verifiedEmail: user.email,
              currency: event.currency,
              withdrawalMethods: this._filterWithdrawalMethods(event.currency),
              shouldDisplayDisclaimer:
                event.currency !== CURRENCIES.EGP && !this.viewState.paymentSuccessful,
            });
          },
        });
        break;
      }
      case 'RequestOTP': {
        this._requestOTP();
        break;
      }
      case 'RequestEmailOTP': {
        this._requestEmailOTP();
        break;
      }
      case 'RequestPhoneNumberChange': {
        this.emitSideEffect({
          type: 'ShowChangeNumber',
          url: `${PHONE_NUMBER_CHANGE_FORM_URL}?merchant_id=${user.id}&email=${user.email}`,
        });
        break;
      }
      case 'RequestEmailChange': {
        this.emitSideEffect({
          type: 'ShowChangeEmail',
          url: `${EMAIL_CHANGE_FORM_URL}?merchant_id=${user.id}&email=${user.email}`,
        });
        break;
      }
      case 'WithdrawalMethodChanged': {
        const paymentType = this._resolvePaymentType(event.paymentMethod);
        this.updateViewState({
          ...this.viewState,
          selectedWithdrawalMethod: event.paymentMethod,
          selectedPaymentType: paymentType,
          minWithdrawalAmount: this._calculateMinimumAllowedAmount(paymentType),
        });
        break;
      }
      case 'WithdrawalAmountChanged': {
        this.updateViewState({
          ...this.viewState,
          withdrawalMethods: this._filterWithdrawalMethods(
            this.viewState.currency!,
            event.amount < 3000,
          ),
        });
        break;
      }
      case 'ClickCloseDialog': {
        this.emitSideEffect({
          type: 'CloseDialog',
        });
        break;
      }
      case 'UpdateOTPOption': {
        this.viewState.selectedOTPOption = event.value;
        this.viewState.withdrawOTPOptions!.forEach((otpOption) => {
          otpOption.isActive = otpOption.value === event.value;
        });
        if (this.otpTimerSubscription) {
          this.otpTimerSubscription.unsubscribe();
          this.otpTimerSubscription = null;
          this.viewState.remainingTimeUntilNextOTP = null;
        }
        break;
      }
    }
  }

  private startOTPButtonTimer(waitTimeInSeconds: number): void {
    if (this.otpTimerSubscription) {
      this.otpTimerSubscription.unsubscribe();
    }
    this.otpTimerSubscription = interval(1000)
      .pipe(take(waitTimeInSeconds))
      .subscribe({
        next: (secondsPassed) => {
          const remainingSecondsUntilNextOTP = waitTimeInSeconds - secondsPassed;
          this.updateViewState({
            ...this.viewState,
            remainingTimeUntilNextOTP: this.secondsToTimeString(remainingSecondsUntilNextOTP),
          });
        },
        complete: () => {
          this.updateViewState({
            ...this.viewState,
            remainingTimeUntilNextOTP: null,
          });
          this.otpTimerSubscription = null; // Clear the subscription when the timer completes
        },
      });
  }

  private _shouldShowEmailOTP(): void {
    this._getFeatureAttributeUseCase.execute(EMAIL_OTP_WITHDRAW).subscribe({
      next: (attribute) => {
        this.viewState.isEmailOTPEnabled = featureAttributeAssign(attribute, user.id);
      },
    });
  }

  private _requestOTP(): void {
    if (this.fieldsValidityChecker()) {
      this._requestWithdrawalOtpUseCase.execute().subscribe({
        next: (otpRequestInfo) => {
          this._checkCode = otpRequestInfo.checkCode;
          const waitTimeInSeconds = otpRequestInfo.timeUntilNextRequest.seconds();
          this.startOTPButtonTimer(waitTimeInSeconds);
          this._trackOTPEvents('sms_otp_requested', { response: otpRequestInfo });
        },
        error: (err) => {
          this._commonOTPError('sms_otp_request_failed', err);
        },
      });
    }
  }

  private _requestEmailOTP(): void {
    if (this.fieldsValidityChecker()) {
      this._requestEmailOTPUseCase.execute('withdrawal').subscribe({
        next: (otpRequest) => {
          this._checkCode = otpRequest.checkCode;
          const waitTimeInSeconds = otpRequest.codeExpiryInMinutes * 60;
          this.startOTPButtonTimer(waitTimeInSeconds);
          this._trackOTPEvents('email_otp_requested', { response: otpRequest });
        },
        error: (err) => {
          this._commonOTPError('email_otp_request_failed', err);
        },
      });
    }
  }

  private _commonOTPError(eventName: string, err: any, request?: any): void {
    if ((err as WithdrawalOTPError) && err.type === 'OTPRequestLimitExceeded') {
      if (err.timeUntilNextRequest != null) {
        this.startOTPButtonTimer(err.timeUntilNextRequest.seconds());
      }
      this.emitSideEffect({
        type: 'ShowError',
        message: 'WALLET.WITHDRAWAL_DIALOG.ERRORS.OTP_REQUEST_LIMIT',
      });
    } else {
      if (err.error?.errorCode === 'rate-limit-triggered') {
        this.emitSideEffect({
          type: 'ShowError',
          message: 'WALLET.WITHDRAWAL_DIALOG.ERRORS.OTP_REQUEST_LIMIT',
        });
      } else {
        this.emitSideEffect({
          type: 'ShowError',
          message: parseError(err),
        });
      }
    }

    const payload = {
      errorObject: err,
      'error-code': err?.error?.errorCode,
      errorMessage: parseError(err),
    };

    this._trackOTPEvents(eventName, payload);
  }

  private _trackOTPEvents(eventName: string, payload?: any): void {
    this._logMixpanelEventUseCase.execute({
      eventName,
      payload: {
        ...payload,
        scope: 'opt-in',
        type: 'withdrawal',
      },
    });
  }

  private secondsToTimeString(totalSeconds: number): string {
    const minutes = Math.trunc(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    let secondsExtraZeroChar = '';
    if (seconds < 10) {
      secondsExtraZeroChar = '0';
    }
    return `${minutes}:${secondsExtraZeroChar}${seconds}`;
  }

  createPayoneerWithdrawalRequest(request: any, otpCode: string): void {
    this.updateViewState({
      ...this.viewState,
      isSubmittingRequest: true,
    });

    let withdrawalObservable: Observable<any>;
    if (this._isOTPEnforced) {
      withdrawalObservable = this._createSecurePayoneerUseCase.execute({
        ...request,
        otpCheckModel: {
          checkCode: this._checkCode!,
          otpCode: otpCode.toString(),
        },
        otpVerificationMode: this.viewState.selectedOTPOption,
      });
    } else {
      withdrawalObservable = this._withdrawPayoneerUseCase.execute({
        ...request,
        currency: request.currency.englishName,
        otpVerificationMode: this.viewState.selectedOTPOption,
      });
    }

    this._executeWithdrawal(withdrawalObservable, request);
  }

  createIntlBankTransferWithdrawalRequest(request: any, otpCode: string): void {
    this.updateViewState({
      ...this.viewState,
      isSubmittingRequest: true,
    });

    let withdrawalObservable: Observable<any>;
    if (this._isOTPEnforced) {
      withdrawalObservable = this._createSecureIntlBankTransferUseCase.execute({
        ...request,
        otpCheckModel: {
          checkCode: this._checkCode!,
          otpCode: otpCode.toString(),
        },
        bankType:
          this.viewState.selectedWithdrawalMethod === WithdrawalMethods.INTL_BANK_TRANSFER_METHOD
            ? 'international'
            : 'egypt',
        otpVerificationMode: this.viewState.selectedOTPOption,
      });
    } else {
      withdrawalObservable = this._withdrawIntlBankTransferUseCase.execute({
        ...request,
        currency: request.currency.englishName,
        bankType:
          this.viewState.selectedWithdrawalMethod === WithdrawalMethods.INTL_BANK_TRANSFER_METHOD
            ? 'international'
            : 'egypt',
        otpVerificationMode: this.viewState.selectedOTPOption,
      });
    }

    this._executeWithdrawal(withdrawalObservable, request);
  }

  /**
   *
   * @param request
   *
   * Create a default withdrawal request. This will be for any other method that does
   * not have a 'special' withdrawal request.
   */
  doMakeDefaultWithdrawalRequest(request: any, otpCode: string): void {
    this.updateViewState({
      ...this.viewState,
      isSubmittingRequest: true,
    });
    let withdrawalObservable: Observable<any>;
    if (this._isOTPEnforced) {
      withdrawalObservable = this._createSecureWithdrawalRequestUseCase.execute(
        request.currency,
        request.amount,
        request.paymentMethod,
        request.phoneNum,
        {
          checkCode: this._checkCode!,
          otpCode: otpCode.toString(),
        },
        request.otpVerificationMode,
      );
    } else {
      withdrawalObservable = this._createWithdrawalRequestUseCase.execute({
        ...request,
        currency: request.currency.englishName,
      });
    }

    this._executeWithdrawal(withdrawalObservable, request);
  }

  private _executeWithdrawal(withdrawalObservable: Observable<any>, request: any): void {
    withdrawalObservable.subscribe({
      next: () => {
        this.updateViewState({
          ...this.viewState,
          paymentSuccessful: true,
        });
        this._trackPaymentEvent(request, 'Payment_success');
      },
      error: (err) => {
        if (err === WithdrawalError.InsufficientFunds) {
          this.emitSideEffect({
            type: 'ShowError',
            message: 'WALLET.WITHDRAWAL_DIALOG.ERRORS.GREATER_AMOUNT_REQUESTED',
          });
        } else if (err === WithdrawalError.WrongOTP) {
          this.emitSideEffect({
            type: 'ShowError',
            message: 'WALLET.WITHDRAWAL_DIALOG.ERRORS.WRONG_OTP',
          });
        } else if (err === WithdrawalError.OTPAlreadyVerified) {
          this.emitSideEffect({
            type: 'ShowError',
            message: 'WALLET.WITHDRAWAL_DIALOG.ERRORS.OTP_ALREADY_VERIFIED',
          });
        } else if (err === WithdrawalError.OTPExpired) {
          this.emitSideEffect({
            type: 'ShowError',
            message: 'WALLET.WITHDRAWAL_DIALOG.ERRORS.OTP_EXPIRED',
          });
        } else {
          this.emitSideEffect({
            type: 'ShowError',
            message: err,
          });
        }
        this.updateViewState({
          ...this.viewState,
          isSubmittingRequest: false,
        });
        this._trackPaymentEvent(request, 'Payment_failed', err);
      },
    });
  }

  private _trackPaymentEvent(
    request: any,
    eventName: 'Payment_success' | 'Payment_failed',
    error?: any,
  ): void {
    this._getUserCountryUseCase
      .execute()
      .pipe(last())
      .subscribe((location) => {
        const payload = {
          'Taager ID': user.id,
          'User Location': location,
          Currency: request.currency,
          Amount: request.amount,
          'Payment Type': this.viewState.selectedPaymentType,
          'Withdraw Type': this.viewState.selectedWithdrawalMethod,
          'OTP Method': this.viewState.selectedOTPOption,
          'Payment Failed Reason': error?.error?.message,
          'Error Object': error,
        };

        this._logGTagEventUseCase.execute({ eventName, payload });
        this._logMixpanelEventUseCase.execute({ eventName, payload });
      });
  }

  private _filterWithdrawalMethods(currency: string, hideBankTransfer = true): WithdrawalMethods[] {
    if (currency === CURRENCIES.EGP) {
      let methods = this._withdrawalMethodsList.filter(
        (method) => method !== WithdrawalMethods.PAYONEER_TRANSFER_METHOD,
      );
      if (hideBankTransfer) {
        methods = methods.filter(
          (method) =>
            method !== WithdrawalMethods.EGP_BANK_TRANSFER_METHOD &&
            method !== WithdrawalMethods.INTL_BANK_TRANSFER_METHOD,
        );
      }
      return methods;
    }
    return this._withdrawalMethodsList;
  }

  private _resolvePaymentType(paymentMethod: WithdrawalMethods): PaymentTypes {
    if (WithdrawalsPaymentTypes[PaymentTypes.MOBILE_WALLET].includes(paymentMethod)) {
      return PaymentTypes.MOBILE_WALLET;
    }
    if (WithdrawalsPaymentTypes[PaymentTypes.BANK_TRANSFER].includes(paymentMethod)) {
      return PaymentTypes.BANK_TRANSFER;
    }
    if (WithdrawalsPaymentTypes[PaymentTypes.DIGITAL_PAYMENT].includes(paymentMethod)) {
      return PaymentTypes.DIGITAL_PAYMENT;
    }
    return PaymentTypes.MOBILE_WALLET;
  }

  private _calculateMinimumAllowedAmount(paymentType: PaymentTypes): number {
    switch (paymentType) {
      case PaymentTypes.BANK_TRANSFER:
        return this.viewState.currency === CURRENCIES.EGP ? 3000 : 100;
      default:
        return 1;
    }
  }

  setFieldsValidityChecker(param: () => boolean): void {
    this.fieldsValidityChecker = param;
  }
}
