import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TallyService } from '../../../shared/services/tally.service';

@Component({
  selector: 'app-withdrawal-change-number',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './withdrawal-change-number.component.html',
  styleUrls: ['./withdrawal-change-number.component.scss'],
})
export class WithdrawalChangeNumberComponent implements OnInit {
  url: string;

  constructor(
    private _tallyService: TallyService,
    public dialogRef: MatDialogRef<WithdrawalChangeNumberComponent>,
  ) {}

  ngOnInit(): void {
    this._tallyService.loadWidget();
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
